import bg from './assets/undraw_social_girl_562b.svg';
import ill from './assets/undraw_social_girl_562b.svg'
import ill2 from './assets/undraw_connected_world.svg'
import './App.css';

function App() {
  return (
    <div className="App">
      <img className="bg" src={bg}></img>
      <div className="left-pane">


      </div>
      <div className="right-pane ">

        <div class="logo-section">
          Interlix
          </div>
        <div className="pane-section  pane-alt">
          <div className="pane-section-right">
            &nbsp; You!
          </div>
          <div className="pane-section-left">
            <img className="illus" src={ill} />
          </div>

        </div>

        <div className="pane-section ">

          <div className="pane-section-right">
            &nbsp; We Network
          </div>
          <div className="pane-section-left">
            <img className="illus" src={ill2} />
          </div>

        </div>

        <div className="pane-section footer">
          <div>
            <div className="f-head">Home </div>
            <div className="f-body">
              <ul>
                <li>Filix Lite $30 3Mbps (Uncapped) </li>
                <li>Filix Intense $45 5Mbps (Uncapped)</li>
                <li>Filix Super $65 8Mbps (Uncapped)</li>
              </ul>
            </div>
          </div>
          <div>
            <div className="f-head">Business </div>
            <div className="f-body">
              <ul>
                <li>Filix Intense $50 5Mbps (Uncapped)</li>
                <li>Filix Super $80 10Mbps (Uncapped)</li>

              </ul><br />
            </div>
          </div>  <div>
            <div className="f-head">Wilix </div>
            <div className="f-body">
              <ul>
                <li>Filix Lite $20 2Mbps (Uncapped)</li>
                <li>Filix Lite $30 5Mbps (Uncapped)</li>
                <li>Filix Lite $65 10Mbps (Uncapped)</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default App;
